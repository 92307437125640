import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { navigate } from 'utils/navigate'
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { Footer, Header, Notification, SEO } from "components";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as loginActions from "state/LoginPage/actions";
import { customerAccountLogout } from "state/LoginPage/actions";
import { actionCreators, selectors } from "state/LoginPage/reducer";
import * as styles from "./ProfilePage.module.scss";

const ProfilePage = ({
  loginState,
  menu,
  resetMenuFilters,
  setMenuCollection,
  customerAccountLogout,
  data
}) => {
  const [notification, showNotification] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showNotification(loginState.UIState.error);
  }, [loginState.UIState.error]);

  const logoutHandler = () => {
    customerAccountLogout();
    navigate("/");
  };

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  const { SEO_TITLE } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.container}>
      <SEO pathname='/profile' title={SEO_TITLE} />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
        isNeedDescription={true}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          {loginState.firstName && `${loginState.firstName}’s `}Account
        </div>

        <ul className={styles.featuresList}>
          <li className={styles.featureListItem}>
            <div className={styles.leftPart}>
              <p className={styles.itemTitle}>Account Details</p>
              {!loginState.address && (
                <p className={styles.itemText}>Address not defined</p>
              )}
              {loginState.address && (
                <>
                  <p className={styles.itemText}>
                    {loginState.firstName} {loginState.lastName}
                  </p>
                  <br />
                  <p className={styles.itemText}>
                    {loginState.address} {loginState.address2}
                  </p>
                  <p className={styles.itemText}>
                    {loginState.city}, {loginState.state}
                  </p>
                  <p className={styles.itemText}>{loginState.zipCode}</p>
                </>
              )}
            </div>
            <div>
              <button
                className={styles.editButton}
                onClick={() => navigate("/profile/account-information")}
              >
                Edit
              </button>
            </div>
          </li>

          <li className={styles.featureListItem}>
            <div className={styles.leftPart}>
              <p className={styles.itemTitle}>Login Information</p>
              <p className={styles.itemText}>{loginState.email}</p>
              <br />
              <p className={styles.itemText}>******</p>
            </div>
            <div>
              <button
                className={styles.editButton}
                onClick={() => navigate("/profile/login-information")}
              >
                Edit
              </button>
            </div>
          </li>

          <li className={styles.featureListItem}>
            <div className={styles.leftPart}>
              <p className={styles.itemTitle}>Subscriptions</p>

              {loginState.membershipInfo.status === "TRIAL" ||
              loginState.membershipInfo.status === "EXPIRING" ||
              loginState.membershipInfo.status === "ACTIVE" ? (
                <div>
                  <div className={styles.itemRow}>
                    <p className={styles.itemTextTitle}>Current Status: </p>

                    {loginState.membershipInfo.status === "EXPIRING" ? (
                      <p className={styles.itemTextStrong1}>
                        VIP Membership{" "}
                        <span className={styles.itemTextTitle}>(expiring)</span>
                      </p>
                    ) : (
                      <p className={styles.itemTextStrong}>VIP Membership</p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.itemRow}>
                    <p className={styles.itemTextTitle}>Current Status: </p>
                    <p className={styles.itemText}>Free Membership</p>
                  </div>
                  <div className={styles.itemRow}>
                    <p className={styles.itemTextTitle}>Available Upgrade: </p>
                    <p className={styles.itemTextStrong}>VIP Membership</p>
                  </div>
                </div>
              )}
            </div>
            <div>
              <button
                className={styles.editButton}
                onClick={() => {
                  loginState.membershipInfo.status === "TRIAL" ||
                  loginState.membershipInfo.status === "EXPIRING" ||
                  loginState.membershipInfo.status === "ACTIVE"
                    ? navigate("/profile/customer-subscriptions")
                    : navigate("/profile/subscriptions");
                }}
              >
                Edit
              </button>
            </div>
          </li>

          <li className={styles.featureListItem}>
            <div className={styles.leftPart}>
              <p className={styles.itemTitle}>Orders</p>
            </div>
            <div>
              <button
                className={styles.editButton}
                onClick={() => navigate("/profile/orders")}
              >
                View
              </button>
            </div>
          </li>
        </ul>

        <div className={styles.btnBlackContainer}>
          <button
            className={styles.blackButton}
            onClick={() => logoutHandler()}
          >
            Log Out
          </button>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification message={notification} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginState: selectors.selectLoginState(state),
  menu: selectMenu(state),
});

const mapDispatchToProps = {
  customerAccountRefreshToken: loginActions.customerAccountRefreshToken,

  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setFirstName: actionCreators.setFirstName,
  setLastName: actionCreators.setLastName,
  setMenuCollection: actionHomeCreators.setMenuCollection,
  customerAccountLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

export const query = graphql`
  query ProfilePage {
    markdownRemark(fields: {slug: {eq: "/profile/"}}) {
      frontmatter {
        SEO_TITLE
      }
    }
  }
`
