// extracted by mini-css-extract-plugin
export var container = "ProfilePage-module--container--1cy-P";
export var content = "ProfilePage-module--content--17orU";
export var title = "ProfilePage-module--title--1HFih";
export var featuresList = "ProfilePage-module--featuresList--3GeN5";
export var featureListItem = "ProfilePage-module--featureListItem--Z-KZk";
export var leftPart = "ProfilePage-module--leftPart--qpQRC";
export var itemTitle = "ProfilePage-module--itemTitle--3MNC0";
export var itemRow = "ProfilePage-module--itemRow--aedEl";
export var itemTextTitle = "ProfilePage-module--itemTextTitle--3byrC";
export var itemText = "ProfilePage-module--itemText--1MeA9";
export var itemTextStrong = "ProfilePage-module--itemTextStrong--2_1k8";
export var itemTextStrong1 = "ProfilePage-module--itemTextStrong1--1pU2L";
export var editButton = "ProfilePage-module--editButton--nePjK";
export var btnBlackContainer = "ProfilePage-module--btnBlackContainer--TjfNt";
export var blackButton = "ProfilePage-module--blackButton--3cGhB";
export var form = "ProfilePage-module--form--37yfC";
export var inputContainer = "ProfilePage-module--inputContainer--3D6Cf";
export var label = "ProfilePage-module--label--WDQjV";
export var btnContainer = "ProfilePage-module--btnContainer--1ztXd";
export var btnUpdateActive = "ProfilePage-module--btnUpdateActive--1MLQA";
export var btnUpdateInactive = "ProfilePage-module--btnUpdateInactive--3R-Mh";
export var footerContainer = "ProfilePage-module--footerContainer--8zrqN";